// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Blog from './Blog'; // Import the newly created Blog component

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Hello, world.</p>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/niklas-hirmke/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check out my LinkedIn profile here
          </a>
          <div>
            <h1>My blog</h1>
            <h2>Blog #01 - July 2023</h2>
            <Link to="/blog">Read more</Link> {/* Add a link to the blog page */}
          </div>
        </header>
      </div>
      <Route path="/blog" component={Blog} /> {/* Set up the route for the Blog component */}
    </Router>
  );
}

export default App;
